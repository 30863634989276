import React from "react"
import styled from "styled-components"
import Colors from "../vars/colors"
import Breakpoints from "../vars/breakpoints"
import Gutters from "../vars/gutters"


const Footer = styled.div`
    background: ${ Colors.tertiary.main };
    color: ${ Colors.text.inverted };

    div:first-child {
        width: ${ Gutters.medium };
        max-width: 90rem;
        margin: 0 auto;

        display: flex;
        flex-flow: row wrap;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-bottom: 2rem;

        li {
        margin-right: 1rem;
        margin-bottom: 1rem;

            a {
                color: ${ Colors.text.inverted };
                text-decoration: none;
            }
        }
    }

    .copyright {
        margin: 0 auto;
    }

    @media only screen and (${ Breakpoints.device.small }) {
        div:first-child {
            flex-flow: row-reverse nowrap;
            justify-content: space-between;
        }

        ul { margin-bottom: 0; width: initial }
        ul li { margin-bottom: 0; }

        .copyright {
            margin: 0;
        }
    }
    
`

const FooterComponent = (props) => {
    const d = new Date().getFullYear().toString();
    return (
        <Footer as='footer'>
            <div>
                <ul>
                    <li><a href="https://s3.eu-west-2.amazonaws.com/assets.vizcareer.com/privacy-policy.pdf">Privacy</a></li>
                    <li><a href="https://s3.eu-west-2.amazonaws.com/assets.vizcareer.com/terms-and-conditions.pdf">Terms &amp; Conditions</a></li>
                    <li><a href="https://s3.eu-west-2.amazonaws.com/assets.vizcareer.com/user-agreement.pdf">User Agreement</a></li>
                    <li><a href="https://s3.eu-west-2.amazonaws.com/assets.vizcareer.com/gdpr-statement.pdf">GDPR Statement</a></li>
                </ul>
                <div className="copyright">
                    &copy; { d } VizCareer Digital Ltd.
                </div>
                { props.children }
            </div>
        </Footer>
    )
}

export default FooterComponent;