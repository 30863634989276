import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Gutters from "../vars/gutters"
import Breakpoints from "../vars/breakpoints"
import Colors from "../vars/colors"

import logo from "../../images/logo/vlogo_fullcolour.svg"

const StyledNavbar = styled.nav`

.navbar {
    width: ${ Gutters.medium };
    max-width: 90rem;
    height: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 auto;
    background: ${ Colors.background.main }
  }
  
  .navbar-logo {
    height: 2rem;
    margin-right: auto;

    img {
      height 100%;
    }
  }
  
  .nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
  }
  
  .nav-item {
    display: flex;
    align-items: center;
    position: relative;
    font-size: 1.25rem;
  }
  
  .nav-links {
    color: ${ Colors.secondary.main };
    text-decoration: none;
    padding: 0.5rem 1rem;
  }
  
  .menu-icon {
    display: none;
  }
  
  // Mobile
  @media only screen and (max-width: ${ Breakpoints.sizes.medium }) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      height: 120%;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-menu.active {
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }

    .nav-menu-background {
      background: rgba(0, 0, 0, 0.6);
      z-index: 1;
      height: 100%;
      transition: all 0.5s ease;
    }
  
    .nav-links {
      background: #fff;
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 16px;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.25rem;
      cursor: pointer;
    }
  
    button {
      display: none;
    }
  }

  // Desktop
  @media only screen and (${ Breakpoints.device.medium }) {
    .nav-item .nav-links {
      margin-right: 0.75rem;
    }
    .nav-item .nav-links.sign-in {
      margin-right: 0;
    }
  }

`

function Navbar() {
    const [click, setClick] = useState(false);

    // Set click to inverse state
    const handleClick = () => {
      setClick(!click);
      if(click === false) {
        // Menu open, prevent page scrolling
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    };

    const closeMobileMenu = () => {
      document.body.classList.remove("no-scroll");
      setClick(false);
    }
      


    return (
        <StyledNavbar>
            <div className="navbar">
                <Link to="/" className="navbar-logo"><img src={ logo } alt="Logo" /></Link>
                <div className="menu-icon" onClick={ handleClick }>
                    <span className={ click ? 'clicked' : 'unclicked' }>MENU</span>
                </div>
                <ul className={ click ? 'nav-menu active' : 'nav-menu' }>
                    <li className='nav-item' >
                      <Link to="/blog" className='nav-links' onClick={ closeMobileMenu }>Blog</Link>
                    </li>
                    <li className='nav-item' >
                      <a href="https://app.vizcareer.com" className='nav-links sign-in' onClick={ closeMobileMenu }>Sign In</a>
                    </li>
                    <div className="nav-menu-background" onClick={ handleClick }></div>
                </ul>
            </div>
        </StyledNavbar>
    )
}

export default Navbar;
