import React from "react"
import styled from "styled-components"

import Breakpoints from "./vars/breakpoints"
import Gutters from "./vars/gutters"


// Container with margin
const StyledMargin = styled.section`
    max-width: ${ props => props.maxwidth ? props.maxwidth : "90rem" };
    margin: 0 auto;

    width: ${ props => props.width === "small" ? Gutters.small : 
              props.width === "large" ? Gutters.large :
              props.width === "xl" ? Gutters.xl : Gutters.medium };
`
const Margin = (props) => {
    if (props.tag) {
        return (
            <StyledMargin className={ props.className } as={ props.tag } dangerouslySetInnerHTML={ props.content } width={ props.width } maxwidth={ props.maxwidth }>
                { props.children }
            </StyledMargin>
        )
    }
        else {
            return (
                <StyledMargin className={ props.className } dangerouslySetInnerHTML={ props.content } width={ props.width } maxwidth={ props.maxwidth }>
                 { props.children }
                </StyledMargin>
            )
        }
}
const MarginNav = (props) => {
    return (
        <StyledMargin as="nav">
            { props.children }
        </StyledMargin>
    )
}



// Container full width
const StyledFullwidth = styled.section`
    // Styles
`
const Fullwidth = ({ className, children }) => {
    return (
        <StyledFullwidth className={ className }>{ children }</StyledFullwidth>
    )
}




const StyledFlexMedium = styled.div`
    display: flex;
    flex-direction: column;
    @media only screen and (${ Breakpoints.device.medium }) {
        flex-direction: row;
    }
`
const FlexMedium = (props) => {
    return (
        <StyledFlexMedium className={ props.className }>
            { props.children }
        </StyledFlexMedium>
    )
}






const values = { Margin, MarginNav, Fullwidth, FlexMedium};
export default values;