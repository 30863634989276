import React from "react"
import styled from "styled-components"



const Main = styled.main`
    display: flex;
    flex-direction: column;
` // Global Main styles here
const MainLayout = ({ children }) => {
    return (
        <Main>{ children }</Main>
    )
}

export default MainLayout